import React, {useContext, useEffect} from "react";

import { graphql } from "gatsby";

import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import {navigate} from "gatsby";

import {authContext} from "../contexts/authContext";

import Section from "../components/Section";
import Layout from "../components/Layout";
import LoginForm from "../components/LoginForm";
import LoaderPage from "../components/LoaderPage";

import appGoogleImg from "../images/appGoogle.jpg";
import appStoreImg from "../images/appStore.jpg";

const LoginPage = (props) => {
	const auth = useContext(authContext);

	useEffect(() => {
		if (auth.isLoggedIn) {
			navigate("/");
		}
	}, [auth.isLoggedIn]);

	return (<Layout location={props.location}>
			{auth.isLoading ? (<LoaderPage/>) : (<Section title={" "}>
					<Section title={" "}>
						<h1><Trans>Logi sisse</Trans></h1>
						<LoginForm/>
					</Section>
					<div
						className="flex flex-col md:flex-row items-center justify-between border-t  pt-8 mt-10 w-full text-sm">
						<p className="max-w-sm text-center md:text-left">
						<Trans>Dr Simeonsi dieedi tugikeskus mobiilirakendus on saadaval siin:</Trans>
						</p>
						<div className="flex">
							<a
								target={"_blank"}
								rel="noreferrer"
								href={"https://play.google.com/store/apps/details?id=com.shternconsulting.simeonsdiet.app"}
								className="max-w-2xl w-full h-auto mr-3"
							>
								<img src={appGoogleImg} alt="GooglePlay"/>
							</a>
							<a
								target={"_blank"}
								rel="noreferrer"
								href={"https://apps.apple.com/us/app/dr-simeonsi-dieedi-tugikeskus/id1435077850?ls=1"}
								className="max-w-2xl w-full h-auto m-0"
							>
								<img src={appStoreImg} alt="AppStore"/>
							</a>
						</div>
					</div>
				</Section>)}
		</Layout>);
};

export default LoginPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;